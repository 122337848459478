<template>
  <div class="content-tab-users" ref="content">
    <div class="box-content">
      <b-row class="mt-1">
        <ProgressUpload />
      </b-row>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2" v-show="filterStatue == 'paye'">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style b-form-select-new-style-w-1"
            v-model="year"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-show="filterStatue == 'paye'">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-show="filterStatue == 'paye'">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  w-63-px"
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  w-63-px"
            v-model="filterFiliale"
            :options="
              filterStatue == 'paye'
                ? [
                    { value: null, text: 'Tous' },
                    ...getcomputedFiliale(filterMonth, filterWeek)
                  ]
                : [{ value: null, text: 'Tous' }, ...getFilialeAdpTh]
            "
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale()"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-show="filterStatue == 'paye'">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>
          <b-form-select
            class="b-form-select-new-style "
            v-model="depot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Statut </span>
          </div>

          <b-form-select
            class="b-form-select-new-style w-63-px"
            v-model="filterStatue"
            :options="[
              { text: 'Facture à éditer', value: 'Facture à éditer' },
              { value: 'paye', text: 'PAYÉ' },
              { value: 'non paye', text: 'ADP' }
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeStatue"
          ></b-form-select>
        </div>
        <input-file-excel-num-dossier
          v-if="filterStatue != 'paye'"
          :value="numbers_from_file"
          @searchWithNumDossier="searchWithMultipleDossier"
        />
        <!-- <input-file-excel-num-dossier
          :value="difference_umbers_from_file"
          @searchWithNumDossier="DifferenceInProjectUploaded"
        /> -->
        <!-- <div class="box-label-champ">
          <b-button
            v-if="
              ComputedButtonValidation === true &&
                cantValid($options.name) &&
                filterMonth != null
            "
            class="button-succes-style ml-2"
            size="sm"
            variant="success"
            @click="handleValider"
          >
            <span
              v-if="
                ComputedButtonValidation === true &&
                  this.getValidationTh === true
              "
              >Validé
              <font-awesome-icon icon="check" class="ml-1" />
            </span>
            <span v-else> à Valider</span>
            <span> à Valider</span>
          </b-button>
        </div> -->
        <div class="reload" v-if="getloadingEditTh" @click="ReloadData">
          Une nouvelle version de donnée disponible . cliquez ici pour
          actualiser la page
        </div>
        <div v-if="getChargementTh || loadingSearchTh" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <!-- <b-button
            v-if="
              ComputedButtonValidation === true &&
                this.getValidationTh === true &&
                cantValid($options.name)
            "
            class="button-error-style"
            size="sm"
            variant="danger"
            @click="handleDeValider"
            >Dévalider
          </b-button> -->
          <Lock v-if="computedCheckAll" :name="$options.name" />
          <div class="profile-corner">
            <select-statue
              v-if="computedCheckAll"
              :name="$options.name"
              @refreshData="fetchAnalyseRapportTh"
            />
          </div>
          <font-awesome-icon
            v-if="this.depot != null && cantDelete($options.name)"
            icon="trash"
            class="trash-reset-style"
            @click.prevent="ResetDataInBase()"
          />
          <SearchInput
            :value="searchValue"
            @changeSearchValue="changeSearchValueGlobale"
          />
          <div class="box-label-champ">
            <div class="label-box-style  label-box-style-w-1 ml-2">
              <span class="title-tabel">Vue </span>
            </div>

            <b-form-select
              class="b-form-select-new-style w-63-px"
              v-model="filterVueD"
              :options="getVueDynamiqueAV"
              text-field="name"
              value-field="id"
            ></b-form-select>
          </div>
          <b-button
            v-if="cantExport($options.name)"
            size="sm"
            variant="light"
            class="button-default-style ml-2"
            @click="exportFiles"
            ><font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
      </div>
      <div class="body-box-rapport">
        <div class="tabs-menu-style">
          <img
            src="../../assets/Icon ionic-md-arrow-dropright-circle.png"
            class="flesh-icon"
            :class="{ 'flesh-icon-rigth': showTabs == false }"
            @click="showTabs = !showTabs"
          />
          <div
            v-for="item in computedTabsList"
            :key="item.title"
            @click="handleChangeTabs(item)"
          >
            <div
              class="box-tabs"
              :class="{ 'Active-tabs-style': selectedTabs == item.title }"
            >
              <div class="icon">
                <img :src="item.img" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <hr class="ligne" />
          </div>
        </div>
        <div
          class="tabs-body-style"
          :class="{
            'display-tabs': showTabs == false,
            'zoom-box':
              (selectedTabs == 'Doublons dossier' ||
                selectedTabs == 'Numéro de dépôt') &&
              zoom == true
          }"
        >
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Répartition types'"
          >
            <div class="title-tab-item">Répartition types</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <Charts
                :label="['Tous', 'invalides', 'valides']"
                :data="computedgetValiditeDataTh"
                :datasets="datasetsValidite"
                :width="217"
                :height="150"
                :type="type"
                chartType="Type"
                @selected="handleChangeType"
                :changeType="computedChengeTypeLegend"
                class="display"
                :changeYearOrWeek="getChangePourcentageTh"
              />
              <div class="surface-box mt-1">
                <div class="valueBoxStatue">
                  <div>
                    <span class="label-green"> PAYÉ :</span>
                    <span v-if="geTtotalPayeNotPayeData">
                      {{ geTtotalPayeNotPayeData.paye }}</span
                    >
                  </div>
                  <div>
                    <span class="label-red"> ADP :</span>
                    <span v-if="geTtotalPayeNotPayeData">
                      {{ geTtotalPayeNotPayeData.non_paye }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="surface-box mt-1 mb-3" v-if="getTotalSurfaceTh">
                <div class="title">Montant Total TH</div>
                <div class="valueBox">{{ getTotalSurfaceTh }} €</div>
              </div>
            </div>
          </div>
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Répartition Anomalies'"
          >
            <div class="title-tab-item">Répartition Anomalies</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <Charts
                class="display"
                :label="computedgetAnomalieLabelTh"
                :data="computedgetAnomalieDataTh"
                :datasets="datasetAomalie"
                chartType="Anomalie"
                :width="217"
                :height="150"
                :type="anomalie"
                @selected="selected"
                :changeYearOrWeek="getChangePourcentageTh"
              />
            </div>
          </div>
          <div class="tab-item-style" v-show="selectedTabs == 'Filtres'">
            <div class="title-tab-item">Filtres</div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <label class="label-tab-style ">Colonne</label>
              <SelectComponent
                :options="ComputedgetAllHeader"
                :value="header"
                label="label"
                champName="colone"
                :change="searchHeaders"
                :track-by="'label'"
                placeholder="Rechercher"
                :searchable="true"
                :max-height="130"
                :showLabels="false"
                classToUse="select-vue-component-style select-vue-component-style-raduis-all mb-3"
              />
              <SearchInput
                :value="searchNum"
                @changeSearchValue="changeSearchValue"
                label="Rechercher dans la colonne"
                classToUse="serachLocale"
              />
            </div>
          </div>

          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Doublons dossier'"
          >
            <div class="title-tab-item">
              Doublons dossiers<font-awesome-icon
                icon="compress"
                class="zoom-icon-style"
                @click.prevent="zoom = !zoom"
              />
            </div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <div
                v-if="getLoadingDoublant"
                class="chargement-message-text ml-2"
              >
                Chargement en cours
              </div>
              <NumerouDoublent
                :depot="ComputedDepotDoublant"
                :annee="ComputedAnneeDoublent"
                :month="ComputedMounthDoublent"
                type="TH"
                :change="ComputedChangeDoublent"
              />
            </div>
          </div>
          <div
            class="tab-item-style"
            v-show="selectedTabs == 'Numéro de dépôt'"
          >
            <div class="title-tab-item">
              <b-button
                size="sm"
                class="button-export-style mr-2 "
                @click="refreshBdd"
              >
                <font-awesome-icon icon="sync-alt"
              /></b-button>
              Numéro de dépôts

              <font-awesome-icon
                icon="compress"
                class="zoom-icon-style"
                @click.prevent="zoom = !zoom"
              />
            </div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <div
                v-if="getLoadingDoublant"
                class="chargement-message-text ml-2"
              >
                Chargement en cours
              </div>
              <NumerouDepotMonquant
                :annee="ComputedAnneeDoublent"
                :update="updateDossierWithoutNumDepot"
              />
            </div>
          </div>
          <div class="tab-item-style" v-show="selectedTabs == 'Dossier erroné'">
            <div class="title-tab-item">
              Dossier erroné
            </div>
            <hr class="ligne" />
            <div class="body-tab-item">
              <div
                v-if="getDossierEronneLoadingTh"
                class="chargement-message-text ml-2"
              >
                Chargement en cours
              </div>
              <DossierEronneTh />
            </div>
          </div>
        </div>
        <div
          class="table-rapport-style"
          :class="{ 'width-table-rapport': showTabs == false }"
        >
          <b-table
            v-if="computedRows && !getErrorTh"
            responsive
            show-empty
            id="my-table"
            class="custom-table-style custom-table-rapport-style "
            :items="computedRows"
            :fields="ComputedgetAllHeader"
            sticky-header
            bordered
            hover
            head-variant="light"
            empty-text="Il n'y a aucun enregistrement à afficher"
            :tbody-tr-class="rowClass"
          >
            <template v-slot:head(anomalie)>
              <button
                ref="anomalie"
                class="btn btn-anomalie"
                :class="{
                  desactive: ShowALL == false,
                  active: ShowALL == true,
                  thColorSearch: getClass('anomalie') === true
                }"
                @click="ShowALL ? hideAllDetails() : showAllDetails()"
              >
                <font-awesome-icon icon="bug" />
              </button>
            </template>
            <template v-slot:head(check_all)>
              <b-form-checkbox
                v-model="checkAll"
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:head()="data">
              <div
                :ref="data.field.key"
                :tabindex="-1"
                :class="{ thColorSearch: getClass(data.field.key) === true }"
              >
                {{ data.field.label }}
              </div>
            </template>
            <template v-slot:cell(check_all)="data">
              <b-form-checkbox
                v-model="data.item.check"
                class="check-th"
                @change="checkAllVerif(data.item.check)"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(anomalie)="data">
              <button
                class="btn btn-anomalie"
                :class="{
                  desactive: data.detailsShowing == false,
                  active: data.detailsShowing == true
                }"
                @click="data.toggleDetails"
              >
                <font-awesome-icon icon="bug" />
              </button>
            </template>
            <template v-slot:cell(numero_dossier)="data">
              <font-awesome-icon
                icon="lock-open"
                class="ml-1"
                v-if="data.item.montant_min_lock == false"
              />
              <font-awesome-icon
                icon="lock"
                class="ml-1"
                v-if="data.item.montant_min_lock == true"
              />
              <font-awesome-icon
                :title="data.item.statue_paye_non_paye"
                icon="circle"
                :class="{
                  'red-color-icon': data.item.statue_paye_non_paye != 'PAYE',
                  'green-color-icon': data.item.statue_paye_non_paye == 'PAYE'
                }"
              />
              <span> {{ data.item.numero_dossier }}</span>
            </template>
            <template v-slot:cell(depot)="data">
              {{ data.item.depot }}
            </template>
            <template v-slot:cell(nom1)="data">
              <div :ref="'nom1' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="nom1"
                  :item="data.item"
                  :value="data.item.nom1"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(ballon)="data">
              <div :ref="'ballon' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="ballon"
                  :item="data.item"
                  :value="data.item.ballon"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(RR)="data">
              <div :ref="'RR' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="RR"
                  :item="data.item"
                  :value="data.item.RR"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(RO)="data">
              <div :ref="'RO' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="RO"
                  :item="data.item"
                  :value="data.item.RO"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(prenom1)="data">
              <div :ref="'prenom1' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="prenom1"
                  :item="data.item"
                  :value="data.item.prenom1"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(adresse)="data">
              <div :ref="'adresse' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="adresse"
                  :item="data.item"
                  :value="data.item.adresse"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(departement)="data">
              <div :ref="'departement' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="departement"
                  :item="data.item"
                  :value="data.item.departement"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(etape)="data">
              <div :ref="'etape' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="etape"
                  :item="data.item"
                  :value="data.item.etape"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(categorie)="data">
              <div :ref="'categorie' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="categorie"
                  :item="data.item"
                  :value="data.item.categorie"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(installeur)="data">
              <div :ref="'installeur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="
                    ComputedEditable && data.item.statue_paye_non_paye != 'PAYE'
                  "
                  champName="installeur"
                  :item="data.item"
                  :value="data.item.installeur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                  :optionsSelect="computedgetListSocieteTh"
                  editableType="select"
                />
              </div>
            </template>
            <template v-slot:cell(code_postal)="data">
              <div :ref="'code_postal' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="code_postal"
                  :item="data.item"
                  :value="data.item.code_postal"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(ville)="data">
              <div :ref="'ville' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="ville"
                  :item="data.item"
                  :value="data.item.ville"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(statut)="data">
              <div :ref="'statut' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="statut"
                  :item="data.item"
                  :value="data.item.statut"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(organisme)="data">
              <div :ref="'organisme' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="organisme"
                  editableType="select"
                  :optionsSelect="computedgetListOrganismeTh"
                  :item="data.item"
                  :value="data.item.organisme"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(agent_confirmateur)="data">
              <div :ref="'agent_confirmateur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="agent_confirmateur"
                  :item="data.item"
                  :value="data.item.agent_confirmateur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(agent_commercial)="data">
              <div :ref="'agent_commercial' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="agent_commercial"
                  editableType="select"
                  :optionsSelect="getListCommercialSedentaireTh"
                  :item="data.item"
                  :value="data.item.agent_commercial"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(agent_technique)="data">
              <div :ref="'agent_technique' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="agent_technique"
                  :item="data.item"
                  :value="data.item.agent_technique"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(regie)="data">
              <div :ref="'regie' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="regie"
                  editableType="select"
                  :optionsSelect="computedgetAllRegieTh"
                  :item="data.item"
                  :value="data.item.regie"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(previsiteur)="data">
              <div :ref="'previsiteur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="previsiteur"
                  editableType="select"
                  :optionsSelect="computedgetListPrevisiteurTh"
                  :item="data.item"
                  :value="data.item.previsiteur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(confirmateur)="data">
              <div :ref="'confirmateur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="confirmateur"
                  editableType="select"
                  :optionsSelect="computedgetListCommercialSedentaireTh"
                  :item="data.item"
                  :value="data.item.confirmateur"
                  type="text"
                  :anomalies="anomalie"
                  :anom="getAnomalieDataTh"
                  :typeData="getValiditeData"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(agent_commercial_terrain)="data">
              <div
                :ref="'agent_commercial_terrain' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  champName="agent_commercial_terrain"
                  editableType="select"
                  :optionsSelect="computedgetListPrevisiteurTh"
                  :item="data.item"
                  :value="data.item.agent_commercial_terrain"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(pose_date)="data">
              <div :ref="'pose_date' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="pose_date"
                  :item="data.item"
                  :value="data.item.pose_date"
                  :anomalies="anomalie"
                  :anom="getAnomalieDataTh"
                  type="datetime"
                  editableType="datetime"
                  :typeData="getValiditeDataTh"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(date_dengagement)="data">
              <div :ref="'date_dengagement' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_dengagement"
                  :item="data.item"
                  :value="data.item.date_dengagement"
                  :anomalies="anomalie"
                  :anom="getAnomalieDataTh"
                  type="date"
                  editableType="datetime"
                  :typeData="getValiditeDataTh"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(date_facture)="data">
              <div :ref="'date_facture' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_facture"
                  :item="data.item"
                  :value="data.item.date_facture"
                  :anomalies="anomalie"
                  :anom="getAnomalieDataTh"
                  type="date"
                  editableType="datetime"
                  :typeData="getValiditeDataTh"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(date_depot_dossier)="data">
              <div :ref="'date_depot_dossier' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_depot_dossier"
                  :item="data.item"
                  :value="data.item.date_depot_dossier"
                  :anomalies="anomalie"
                  :anom="getAnomalieDataTh"
                  type="date"
                  editableType="datetime"
                  :typeData="getValiditeDataTh"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(date_creation_de_la_fiche)="data">
              <div
                :ref="'date_creation_de_la_fiche' + data.index"
                :tabindex="-1"
              >
                <EditableInput
                  :editable="ComputedEditable"
                  champName="date_creation_de_la_fiche"
                  :item="data.item"
                  :value="data.item.date_creation_de_la_fiche"
                  :typefilter="type"
                  :anomalies="anomalie"
                  type="date"
                  editableType="datetime"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(previsite_date)="data">
              <div :ref="'previsite_date' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="previsite_date"
                  :item="data.item"
                  :value="data.item.previsite_date"
                  :anomalies="anomalie"
                  :anom="getAnomalieDataTh"
                  type="date"
                  editableType="datetime"
                  :typeData="getValiditeDataTh"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(pose_semaine)="data">
              <div :ref="'pose_semaine' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="pose_semaine"
                  :item="data.item"
                  :value="data.item.pose_semaine"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(poseur)="data">
              <div :ref="'poseur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="poseur"
                  editableType="select"
                  :optionsSelect="computedgetListPoseurTh"
                  :item="data.item"
                  :value="data.item.poseur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(montant_total_ht)="data">
              <div :ref="'montant_total_ht' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_total_ht"
                  :item="data.item"
                  :value="data.item.montant_total_ht"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(montant_net_ht)="data">
              <div :ref="'montant_net_ht' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_net_ht"
                  :item="data.item"
                  :value="data.item.montant_net_ht"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(coef_prime_installateur)="data">
              <div :ref="'coef_prime_installateur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="
                    ComputedEditable && data.item.montant_min_lock == false
                  "
                  champName="coef_prime_installateur"
                  :item="data.item"
                  :value="data.item.coef_prime_installateur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(montant_total_ttc)="data">
              <div :ref="'montant_total_ttc' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_total_ttc"
                  :item="data.item"
                  :value="data.item.montant_total_ttc"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(montant_prime_cee)="data">
              <div :ref="'montant_prime_cee' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_prime_cee"
                  :item="data.item"
                  :value="data.item.montant_prime_cee"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(montant_mpr)="data">
              <div :ref="'montant_mpr' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_mpr"
                  :item="data.item"
                  :value="data.item.montant_mpr"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(kwh_cumac)="data">
              <div :ref="'kwh_cumac' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="kwh_cumac"
                  :item="data.item"
                  :value="data.item.kwh_cumac"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(coposeurs)="data">
              <div :ref="'coposeurs' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="coposeurs"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: null },
                    ...computedgetListPoseurTh
                  ]"
                  :item="data.item"
                  :value="data.item.coposeurs"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(depot_stockage)="data">
              <div :ref="'depot_stockage' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="depot_stockage"
                  :item="data.item"
                  :value="data.item.depot_stockage"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(precarite)="data">
              <div :ref="'precarite' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="precarite"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: 'Classique' },
                    { full_name: 'Grande' },
                    { full_name: 'Simple' }
                  ]"
                  :item="data.item"
                  :value="data.item.precarite"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(type_de_chauffage)="data">
              <div :ref="'type_de_chauffage' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="type_de_chauffage"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: 'Electrique' },
                    { full_name: 'Combustible' }
                  ]"
                  :item="data.item"
                  :value="data.item.type_de_chauffage"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(personnes_au_foyer)="data">
              <div :ref="'personnes_au_foyer' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="personnes_au_foyer"
                  :item="data.item"
                  :value="data.item.personnes_au_foyer"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(numero_de_facture)="data">
              <div :ref="'numero_de_facture' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="numero_de_facture"
                  :item="data.item"
                  :value="data.item.numero_de_facture"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(numero_de_lot)="data">
              <div :ref="'numero_de_lot' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="numero_de_lot"
                  :item="data.item"
                  :value="data.item.numero_de_lot"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(numero_de_depot)="data">
              <div :ref="'numero_de_depot' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="numero_de_depot"
                  :item="data.item"
                  :value="data.item.numero_de_depot"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(revenu_fiscal_ref)="data">
              <div :ref="'revenu_fiscal_ref' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="revenu_fiscal_ref"
                  :item="data.item"
                  :value="data.item.revenu_fiscal_ref"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(email)="data">
              <div :ref="'email' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="email"
                  :item="data.item"
                  :value="data.item.email"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(societe_poseur)="data">
              <div :ref="'societe_poseur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="societe_poseur"
                  :item="data.item"
                  :value="data.item.societe_poseur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(responsable_zone)="data">
              <div :ref="'responsable_zone' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="responsable_zone"
                  :item="data.item"
                  :value="data.item.responsable_zone"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(pipedrive_lead_id)="data">
              <div :ref="'pipedrive_lead_id' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="pipedrive_lead_id"
                  :item="data.item"
                  :value="data.item.pipedrive_lead_id"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(type_lead)="data">
              <div :ref="'type_lead' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="type_lead"
                  :item="data.item"
                  :value="data.item.type_lead"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(montant_operation)="data">
              <div :ref="'montant_operation' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="montant_operation"
                  :item="data.item"
                  :value="data.item.montant_operation"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(campagne)="data">
              <div :ref="'campagne' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="campagne"
                  :item="data.item"
                  :value="data.item.campagne"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(ref_dossier_externe)="data">
              <div :ref="'ref_dossier_externe' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="ref_dossier_externe"
                  :item="data.item"
                  :value="data.item.ref_dossier_externe"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(couleur)="data"
              ><div :ref="'couleur' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="couleur"
                  editableType="select"
                  :optionsSelect="computedgetListCouleur"
                  :item="data.item"
                  :value="data.item.couleur"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                /></div
            ></template>
            <template v-slot:cell(source)="data">
              <div :ref="'source' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="source"
                  :item="data.item"
                  :value="data.item.source"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(commentaire)="data">
              <div :ref="'commentaire' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="commentaire"
                  :item="data.item"
                  :value="data.item.commentaire"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(zone_climatique)="data">
              <div :ref="'zone_climatique' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="zone_climatique"
                  editableType="select"
                  :optionsSelect="[
                    { full_name: 'H1' },
                    { full_name: 'H2' },
                    { full_name: 'H3' }
                  ]"
                  :item="data.item"
                  :value="data.item.zone_climatique"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(dossier_mpr)="data"
              ><div :ref="'dossier_mpr' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="dossier_mpr"
                  editableType="select"
                  :optionsSelect="computedgetListMPR"
                  :item="data.item"
                  :value="data.item.dossier_mpr"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                /></div
            ></template>
            <template v-slot:cell(surface_101_souflee)="data">
              <div :ref="'surface_101_souflee' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_101_souflee"
                  :item="data.item"
                  :value="data.item.surface_101_souflee"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(surface_101_deroule)="data">
              <div :ref="'surface_101_deroule' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_101_deroule"
                  :item="data.item"
                  :value="data.item.surface_101_deroule"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(surface_101_rampants)="data">
              <div :ref="'surface_101_rampants' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_101_rampants"
                  :item="data.item"
                  :value="data.item.surface_101_rampants"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102_murs_iti)="data">
              <div :ref="'surface_102_murs_iti' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_102_murs_iti"
                  :item="data.item"
                  :value="data.item.surface_102_murs_iti"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(surface_102_murs_ite)="data">
              <div :ref="'surface_102_murs_ite' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_102_murs_ite"
                  :item="data.item"
                  :value="data.item.surface_102_murs_ite"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond)="data">
              <div :ref="'surface_103_plafond' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="surface_103_plafond"
                  :item="data.item"
                  :value="data.item.surface_103_plafond"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(VMC)="data">
              <div :ref="'VMC' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="ComputedEditable"
                  champName="VMC"
                  :item="data.item"
                  :value="data.item.VMC"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>

            <template v-slot:cell(montant_a_5_ht)="data">
              <div :ref="'montant_a_5_ht' + data.index" :tabindex="-1">
                {{ data.item.montant_a_5_ht }}
              </div>
            </template>
            <template v-slot:cell(montant_final)="data">
              <div :ref="'montant_final' + data.index" :tabindex="-1">
                <EditableInput
                  :editable="
                    ComputedEditable && data.item.montant_min_lock == false
                  "
                  champName="montant_final"
                  :item="data.item"
                  :value="data.item.montant_final"
                  type="text"
                  :anomalies="anomalie"
                  :updateFunction="updateRowTh"
                  :filterObject="cumputedAllFilter"
                />
              </div>
            </template>
            <template #row-details="data">
              <b-card class="cardAnomalie" v-if="data.item.anomalies">
                <p><strong>Anomalie </strong></p>
                <b-row
                  class="mb-2"
                  v-for="anomalie in data.item.anomalies"
                  :key="anomalie.id"
                >
                  <div
                    class="anomalie"
                    @click="focus(anomalie.name + data.index)"
                  >
                    <b>{{ anomalie.name | MessageFormat }} </b>
                  </div>
                  <div class="anomalie">{{ anomalie.message }}</div>
                </b-row>
              </b-card>
              <b-card class="classAlert" v-if="data.item.alerts">
                <p><strong>Alerts</strong></p>
                <b-row
                  class="mb-2"
                  v-for="alert in data.item.alerts"
                  :key="alert.id"
                >
                  <div class="anomalie" @click="focus(alert.name + data.index)">
                    <b>{{ alert.name | MessageFormat }} </b>
                  </div>
                  <div class="anomalie">{{ alert.message }}</div>
                </b-row>
              </b-card>
            </template>
          </b-table>
          <div v-if="this.computedRows && !getErrorTh" class=" footer-style">
            <b-pagination
              v-model="page"
              :total-rows="getTotalRowsTh"
              :per-page="perPage"
              aria-controls="my-table"
              pills
              align="center"
              size="sm"
              @change="pagination"
              class="pagination-style"
            ></b-pagination>
            <div class="per-page-element-style">
              <div class="box-label-champ">
                <div class="label-box-style">
                  <span class="title-tabel">Eléments par page</span>
                </div>
              </div>
              <b-form-select
                v-model="perPage"
                :options="perPageList"
                @change="changePerPage"
                class="b-form-select-new-style bg-select"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>

      <b-row
        align-h="center"
        class="mt-2  justify-content-between"
        v-if="getErrorTh"
      >
        <b-modal
          id="bv-modal-erreur"
          ref="bv-modal-erreur"
          hide-footer
          hide-header
          centered
        >
          <div class="d-block">
            <h5>{{ getErrorTh }}</h5>
          </div>
          <div class="actionModel">
            <b-button
              class="button-cancel-style"
              @click="$bvModal.hide('bv-modal-erreur')"
              >OK</b-button
            >
          </div>
        </b-modal>
      </b-row>
    </div>
    <canvas
      id="canvas"
      height="320"
      width="550"
      class="graph-filiale"
      style="display:none"
    ></canvas>
    <loader v-if="this.getLoadingTh || loading" />
  </div>
</template>
<script
  type="text/javascript"
  src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.2/Chart.min.js"
></script>
<script>
import moment from 'moment';
import loader from '../Loader';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Analyse-et-validation-TH',
  data() {
    return {
      filterVueD: null,
      searchValue: null,
      filterStatue: 'paye',
      filterFiliale: null,
      currentStatut: null,
      filterMonth: null,
      filterWeek: null,
      zoom: false,
      showTabs: true,
      numbers_from_file: null,
      difference_umbers_from_file: null,
      selectedTabs: 'Répartition types',
      TabsList: [
        {
          title: 'Répartition types',
          img: 'image/Icon-ionic-ios-pie.png'
        },
        {
          title: 'Répartition Anomalies',
          img: 'image/Icon-ionic-ios-pie.png'
        },
        {
          title: 'Filtres',
          img: 'image/Icon-material-filter-list.png'
        },
        {
          title: 'Numéro de dépôt',
          img: 'image/Icon-num-depot.png'
        },
        {
          title: 'Doublons dossier',
          img: 'image/icon-folder.png'
        },
        {
          title: 'Dossier erroné',
          img: 'image/icon-folder.png'
        }
      ],
      selectedFiliale: null,
      myLine: null,
      dataurl: '',
      messageValidation: null,
      header: null,
      searchNum: null,
      depot: null,
      changeYearOrWeek: false,
      changeTypeLegende: false,
      datasetsValidite: {
        position: 'left',
        title: 'Répartition types',
        backgroundColor: ['#4D4BAC', '#DC3C3C', '#699D86']
      },
      datasetAomalie: {
        position: 'right',
        title: 'Répartition anomalies',
        backgroundColor: [
          '#000000',
          '#660033',
          '#CC0000',
          '#FF6666',
          '#CC0066',
          '#FFCCE5',
          '#FFCCFF',
          '#FFCCCC',
          '#F5DEB3',
          '#eca3a9',
          '#E0E0E0',
          '#A0A0A0'
        ]
      },
      year: null,
      type: null,
      anomalie: [],
      filiale: null,
      ListYear: [],
      ShowALL: false,
      page: 1,
      perPage: 100,
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 },
        { value: 500, text: 500 }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-row-th',
          tdClass: 'col-check-row-th'
        },
        {
          key: 'anomalie',
          label: 'Anomalie',
          thClass: 'th-anomalie-th',
          tdClass: 'col-anomalie-th'
        }
      ],
      changeDoublent: true,
      checkAll: false,
      loading: false
    };
  },
  methods: {
    ...mapActions([
      'getSettingOrgenismeTh',
      'fetchAnalyseTh',
      'updateRowTh',
      'exportFileTh',
      'fetchProgressAnalyseTh',
      'fetchAnalyseChartTh',
      'validStepTh',
      'fetchEmployeParTypeTh',
      'fetchAllRegieForAdmin',
      'fastSearchTh',
      'ReloadChartDataTh',
      'getAllFilialesTh',
      'getAnomalieForFilialeTh',
      'getPdfRapportFiliale',
      'ChangeCurrentTpe',
      'ResetDataForWeekTh',
      'fetchMonthForYearTH',
      'updateDossierWithoutNumDepot',
      'resfershBddAnalyseValidation',
      'changeStatutInAnalyseValidation',
      'featchDepotForYearTH',
      'getSettingFilialeTh',
      'fastSearchColonneCotentTh',
      'fetchFilialeADPThForYearTH',
      'getAllVueDynamiqueAV',
      'uploadExcelFileToFundDifference'
    ]),
    ...mapMutations(['SET_LOADING_EDIT_TH', 'SET_DEVALIDATION_PARAMS_TH']),
    async refreshBdd() {
      const response = await this.resfershBddAnalyseValidation();
      if (response.succes) {
        let text =
          ' fixed: ' +
          response.data.fixed +
          ' not fixed : ' +
          response.data.not_fixed +
          ' total : ' +
          response.data.total;
        this.$confirm('', text, 'success').then(() => {
          this.fetchAnalyseRapportTh();
        });
      } else {
        this.$confirm('', response.error, 'error').then(() => {});
      }
    },
    async fetchAnalyseRapportTh(item) {
      let data = {
        depot: this.depot,
        type: this.type,
        anomalies: this.anomalie,
        anom: this.getAnomalieDataTh,
        typeData: this.getValiditeDataTh,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        month: this.filterMonth,
        page: this.page,
        per_page: this.perPage,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        statue: this.filterStatue,
        numbers_from_file: this.numbers_from_file
      };
      if (item) {
        data.changeWeekYear = true;
      }
      const response = await this.fetchAnalyseTh(data);
      if (item) {
        this.changeDoublent = !this.changeDoublent;
      }
      if (response && this.getErrorTh == null) {
        this.ReloadChartDataTh();
      }
      if (this.getErrorTh != null) {
        this.$refs['bv-modal-erreur'].show();
      }
    },

    handleChangeTabs(tab) {
      if (tab.title != 'Filiale' && tab.title != 'Intervalles validés') {
        this.selectedTabs = tab.title;
      }
    },
    FilterfetchAnalyseChartTH() {
      this.fetchAnalyseChartTh(this.cumputedAllFilter);
    },

    async done() {
      var url = this.myLine.toBase64Image();
      this.dataurl = url;
      await this.getPdfRapportFiliale({
        depot: this.depot,
        annee: this.year,
        filiale: this.selectedFiliale,
        url: this.dataurl,
        month: this.filterMonth
      });
      this.selectedFiliale.loading = false;
      this.selectedFiliale = null;
    },

    ChangeMessageValidation(payload) {
      this.messageValidation = payload;
    },
    handleValider() {
      var message = 'le mois ' + this.filterMonth;
      this.$confirm('Êtes-vous sur de vouloir valider  ' + message)
        .then(() => {
          this.validStepTh({
            depot: this.depot,
            year: this.year,
            type: 'TH',
            month: this.filterMonth
          }).then(() => {
            this.$nextTick(() => {
              this.SET_DEVALIDATION_PARAMS_TH(false);
            });
          });
        })
        .catch(error => {});
    },
    handleDeValider() {
      var message = 'la depot' + this.depot;
      this.$confirm('Êtes-vous sur de vouloir dévalider  ' + message)
        .then(() => {
          this.SET_DEVALIDATION_PARAMS_TH(true);
        })
        .catch(error => {});
    },
    ReloadData() {
      this.SET_LOADING_EDIT_TH(false);
      this.fetchAnalyseRapportTh();
    },
    selected(item) {
      if (this.type != 'invalides') {
        this.type = 'invalides';
        this.changeTypeLegende = !this.changeTypeLegende;
      }
      if (this.anomalie.find(anomalie => anomalie === item) === undefined) {
        this.anomalie.push(item);
      } else {
        this.anomalie = this.anomalie.filter(iteam => iteam != item);
      }
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.FilterfetchAnalyseChartTH();
    },
    handleChangeMonth() {
      this.depot = null;
      this.filterWeek = null;
      this.filterFiliale = null;
      this.changeDoublent = !this.changeDoublent;
      this.setLocalStorageRapport();
      this.fetchAnalyseRapportTh(true);
    },
    handleChangeType(item) {
      if (item == 'Tous') {
        this.type = null;
      } else {
        this.type = item;
      }
      this.setLocalStorageRapport();
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.FilterfetchAnalyseChartTH();
    },
    focus(key) {
      this.$refs[key].focus({ preventScroll: false });
    },
    getClass(payload) {
      if (this.header && this.header.key && this.header.key === payload) {
        return true;
      } else {
        return false;
      }
    },
    searchHeaders(payload) {
      this.header = payload.value;
      if (this.header && this.header.key && this.$refs[this.header.key]) {
        this.$refs[this.header.key].focus({ preventScroll: false });
      }
      this.setLocalStorageRapport();
    },
    async handleChangeYear() {
      this.SET_DEVALIDATION_PARAMS_TH(false);
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.type = null;
      this.anomalie = [];
      this.depot = null;
      this.filterMonth = null;
      this.filterWeek = null;
      this.filterFiliale = null;
      this.fetchMonthForYearTH(this.year);
      this.setLocalStorageRapport();
      this.fetchAnalyseRapportTh(true);
      this.featchDepotForYearTH(this.year);
    },
    async handleChangeWeek() {
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.type = null;
      this.depot = null;
      this.filterFiliale = null;
      this.anomalie = [];
      this.setLocalStorageRapport();
      this.fetchAnalyseRapportTh(true);
    },
    async handleChangeFiliale() {
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      if (this.filterStatue == 'paye') {
        this.type = null;
        this.depot = null;
        this.anomalie = [];
      } else {
        this.numbers_from_file = null;
      }
      if (this.filterFiliale === undefined) {
        this.filterFiliale = JSON.parse(
          localStorage.getItem('Rapport-Filters-Th')
        )?.filiale;
      }
      this.setLocalStorageRapport();
      this.fetchAnalyseRapportTh(true);
    },
    async handleChangeDepot() {
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.type = null;
      this.anomalie = [];
      this.setLocalStorageRapport();
      this.fetchAnalyseRapportTh(true);
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.fetchAnalyseRapportTh();
      this.ShowALL = false;
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-rapport-th', this.page);
      this.fetchAnalyseRapportTh();
    },
    handleChangeStatue() {
      this.filterFiliale = null;
      this.numbers_from_file = null;
      this.fetchFilialeADPThForYearTH();
      this.setLocalStorageRapport();
      this.fetchAnalyseRapportTh();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.anomalies) return 'ligneAnomalie';
      else return 'ligneAlert';
    },
    async exportFiles() {
      await this.exportFileTh({
        depot: this.depot,
        type: this.type,
        anomalies: this.anomalie,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        month: this.filterMonth,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        statue: this.filterStatue,
        numbers_from_file: this.numbers_from_file,
        view: this.filterVueD
      });
    },
    showAllDetails() {
      this.computedRows.forEach(item => {
        this.$set(item, '_showDetails', true);
      });
      this.ShowALL = !this.ShowALL;
    },
    hideAllDetails() {
      this.computedRows.forEach(item => {
        this.$set(item, '_showDetails', false);
      });
      this.ShowALL = !this.ShowALL;
    },
    async changeSearchValueGlobale(e) {
      this.searchValue = e;
      await this.fastSearchColonneCotentTh({
        depot: this.depot,
        type: this.type,
        anomalies: this.anomalie,
        anom: this.getAnomalieDataTh,
        typeData: this.getValiditeDataTh,
        annee: this.year,
        name: this.searchNum,
        champ: this.header,
        month: this.filterMonth,
        page: this.page,
        per_page: this.perPage,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        statue: this.filterStatue,
        numbers_from_file: this.numbers_from_file,
        searchValue: this.searchValue
      });
      if (this.getErrorTh != null) {
        this.$refs['bv-modal-erreur'].show();
      }
    },
    changeSearchValue(e) {
      this.searchNum = e;
      this.fastSearchTh({
        page: this.page,
        per_page: this.perPage,
        type: this.type,
        depot: this.depot,
        annee: this.year,
        coloneValue: this.searchNum,
        anom: this.getAnomalieDataTh,
        typeData: this.getValiditeDataTh,
        anomalies: this.anomalie,
        champ: this.header,
        month: this.filterMonth
      });
    },
    setLocalStorageRapport() {
      localStorage.setItem(
        'Rapport-Filters-Th',
        JSON.stringify({
          year: this.year,
          depot: this.depot,
          type: this.type,
          anomalie: this.anomalie,
          month: this.filterMonth,
          filiale: this.filterFiliale,
          week: this.filterWeek,
          statue: this.filterStatue
        })
      );
    },
    ResetDataInBase() {
      this.$confirm(
        'Êtes-vous sur de vouloir supprimer rapport , les salaires et les factures pour la depot ' +
          this.depot
      )
        .then(() => {
          this.ResetDataForWeekTh({
            year: this.year,
            depot: this.depot
          }).then(() => {});
        })
        .catch(error => {});
    },
    checkAllFunction() {
      this.computedRows.map(item => (item.check = this.checkAll));
    },
    checkAllVerif(item) {
      if (item == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    searchWithMultipleDossier(value) {
      this.numbers_from_file = value;
      this.fetchAnalyseRapportTh();
    },
    DifferenceInProjectUploaded(value) {
      this.difference_umbers_from_file = value;
      this.uploadExcelFileToFundDifference();
    }
  },
  filters: {
    MessageFormat: value => {
      switch (value) {
        case 'prenom1':
          return 'prenom';
        case 'first_name':
          return 'nom';
        case 'numero_dossier':
          return 'numero de dossier';
        case 'num_depot':
          return 'numero depot';
        case 'previsiteur':
          return 'previsiteur at';
        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    },
    AnomalieFormat: value => {
      switch (value) {
        case 'previsiteur':
          return 'Prévisiteur ';
        case 'depot_stockage':
          return 'Depot ';
        case 'surface_101':
          return 'Total surface 101 est incorrect';
        case 'surface_102':
          return 'Total surface 102 est incorrect';
        case 'surface_103':
          return 'Total surface 103 est incorrect';
        case 'total_surface_a_isoler':
          return 'Total surface a isoler est incorrect';
        default:
          return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
            /_/g,
            ' '
          );
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingSearchTh',
      'SettingOrganismeTh',
      'getDataTh',
      'getLoadingTh',
      'getTotalRowsTh',
      'getValiditeDataTh',
      'getAnomalieDataTh',
      'getErrorTh',
      'getStatusForRefrechData',
      'getloadingEditTh',
      'getChargementTh',
      'getStatuTh',
      'getValidationTh',
      'getListCommercialSedentaireTh',
      'getListCommercialTerrainTh',
      'getListPoseurTh',
      'getListPrevisiteurTh',
      'getAllRegie',
      'getTotalSurfaceTh',
      'getProgressValidation',
      'getChangePourcentageTh',
      'getDossierDoublentTh',
      'cantUpdate',
      'cantExport',
      'cantValid',
      'cantDelete',
      'getMonthForYearTh',
      'getLoadingDoublant',
      'getNumDepotVideTh',
      'getDossierEronneTh',
      'getDossierEronneLoadingTh',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'geTtotalPayeNotPayeData',
      'getSettingFilialesTh',
      'getFilialeAdpTh',
      'getVueDynamiqueAV'
    ]),
    cumputedAllFilter() {
      return {
        typeData: this.getValiditeDataTh,
        name: this.searchNum,
        month: this.filterMonth,
        anom: this.getAnomalieDataTh,
        anomalies: this.anomalie,
        depot: this.depot,
        annee: this.year,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        week: this.filterWeek,
        page: this.page,
        per_page: this.perPage,
        type: this.type,
        champ: this.header,
        statue: this.filterStatue,
        numbers_from_file: this.numbers_from_file
      };
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedDepot() {
      return [];
    },
    ComputedEditable() {
      return this.cantUpdate(this.$options.name);
    },

    computedgetListCommercialSedentaireTh() {
      if (this.getListCommercialSedentaireTh) {
        const list = this.getListCommercialSedentaireTh.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListCommercialTerrainTh() {
      if (this.getListCommercialTerrainTh) {
        const list = this.getListCommercialTerrainTh.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListPrevisiteurTh() {
      if (this.getListPrevisiteurTh) {
        const list = this.getListPrevisiteurTh.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListPoseurTh() {
      if (this.getListPoseurTh) {
        const list = this.getListPoseurTh.map(item => ({
          full_name: item.full_name
        }));
        return list;
      }
      return [];
    },
    computedgetListSocieteTh() {
      if (this.getSettingFilialesTh) {
        const list = this.getSettingFilialesTh.map(item => ({
          full_name: item.name
        }));
        return list;
      }
      return [];
    },
    computedgetListOrganismeTh() {
      if (this.SettingOrganismeTh) {
        const list = this.SettingOrganismeTh.map(item => ({
          full_name: item.name
        }));
        return list;
      }
      return [];
    },
    computedgetAllRegieTh() {
      if (this.getAllRegie) {
        const list = this.getAllRegie.map(item => ({
          full_name: item.name
        }));
        return list;
      }
      return [];
    },
    computedRows() {
      if (!this.getDataTh) {
        return [];
      }
      if (this.type === 'valides') {
        return this.getDataTh.filter(
          row => !row.anomalies || row.anomalies.length == 0
        );
      } else if (this.type === 'invalides') {
        return this.getDataTh.filter(
          row => row.anomalies && row.anomalies.length > 0
        );
      }

      return this.getDataTh;
    },
    computedgetAnomalieDataTh() {
      if (this.getAnomalieDataTh) {
        return this.getAnomalieDataTh.map(anomalies => {
          return anomalies.pourcentage;
        });
      }
      return [];
    },
    computedgetAnomalieLabelTh() {
      if (this.getAnomalieDataTh) {
        return this.getAnomalieDataTh.map(anomalies => {
          return anomalies.name;
        });
      }
      return [];
    },
    computedgetValiditeDataTh() {
      if (this.getValiditeDataTh) {
        return this.getValiditeDataTh.map(anomalies => {
          return anomalies.pourcentage;
        });
      }
      return [];
    },
    computedChengeTypeLegend() {
      return this.changeTypeLegende;
    },
    ComputedgetAllHeader() {
      if (this.filterVueD != null) {
        let coloms = [];
        coloms = this.getVueDynamiqueAV.find(item => item.id == this.filterVueD)
          ?.columns;
        return [...this.fields, ...coloms];
      }
      return this.fields;
    },
    ComputedButtonValidation() {
      this.ChangeMessageValidation(null);
      if (this.getStatuTh !== 'complet') {
        return false;
      } else {
        if (this.getDossierDoublentTh.length == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    computedgetListCouleur() {
      return [
        { full_name: 'Bleu' },
        { full_name: 'Jaune' },
        { full_name: 'Violet' },
        { full_name: 'Rose' }
      ];
    },
    computedgetListMPR() {
      return [{ full_name: 'Non' }, { full_name: 'Oui' }];
    },
    computedTabsList() {
      let list = this.TabsList;
      if (this.getDossierDoublentTh.length == 0) {
        list = list.filter(item => item.title != 'Doublons dossier');
      }
      if (this.getNumDepotVideTh.length == 0) {
        list = list.filter(item => item.title != 'Numéro de dépôt');
      }
      if (this.getDossierEronneTh.length == 0) {
        list = list.filter(item => item.title != 'Dossier erroné');
      }
      return list;
    },
    ComputedDepotDoublant() {
      return this.depot;
    },
    ComputedAnneeDoublent() {
      return this.year;
    },
    ComputedMounthDoublent() {
      return this.filterMonth;
    },
    ComputedChangeDoublent() {
      return this.changeDoublent;
    },
    computedCheckAll() {
      let tab = this.computedRows.filter(item => item.check == true);
      if (tab.length) {
        return true;
      }
      return false;
    }
  },
  async mounted() {
    this.loading = true;
    this.ChangeCurrentTpe({
      currentType: { name: 'Bar TH', value: 'Th' },
      reload: true
    });
    var courantYear = moment().year();
    if (localStorage.getItem('Rapport-Filters-Th')) {
      this.year = JSON.parse(localStorage.getItem('Rapport-Filters-Th')).year;
    } else {
      this.year = courantYear;
    }
    await this.fetchMonthForYearTH(this.year);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;

    if (localStorage.getItem('Rapport-Filters-Th')) {
      const localstorge = JSON.parse(
        localStorage.getItem('Rapport-Filters-Th')
      );
      this.depot = localstorge.depot;
      this.type = localstorge.type;
      this.anomalie = localstorge.anomalie;
      this.filterMonth = localstorge.month;
      this.filterWeek = localstorge.week;
      this.filterFiliale = localstorge.filiale;
      this.filterStatue = localstorge.statue;
      // this.fetchMonthForYearTH(this.year);
    } else {
      this.filterMonth = courantMonth;
    }
    this.setLocalStorageRapport();

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    if (sessionStorage.getItem('page-rapport-th')) {
      this.page = sessionStorage.getItem('page-rapport-th');
    } else {
      sessionStorage.setItem('page-rapport-th', this.page);
    }
    this.featchDepotForYearTH(this.year);
    const responseFields = await this.getAllVueDynamiqueAV({ blocked: false });
    if (responseFields && this.getVueDynamiqueAV.length) {
      this.filterVueD = this.getVueDynamiqueAV[0].id;
    }
    this.fetchEmployeParTypeTh();
    this.fetchAllRegieForAdmin();
    await this.fetchAnalyseRapportTh();
    this.loading = false;
    this.changeDoublent = !this.changeDoublent;
    this.fetchFilialeADPThForYearTH();

    this.SET_LOADING_EDIT_TH(false);
    this.getSettingFilialeTh({
      type: null,
      type_societe: null,
      search: null
    });
    this.getSettingOrgenismeTh();
  },
  watch: {
    getStatusForRefrechData: function() {
      this.fetchProgressAnalyseTh(this.cumputedAllFilter);
    }
  },
  components: {
    loader,
    EditableInput: () => import('../component/EditableInput.vue'),
    Charts: () => import('../component/Chart.vue'),
    SelectComponent: () => import('../SelectComponent.vue'),
    ProgressUpload: () => import('../component/progressUpload.vue'),
    SearchInput: () => import('../component/SearchInput.vue'),
    NumerouDoublent: () => import('../component/NumerouDoublent.vue'),
    DossierEronneTh: () => import('../component/DossierEronneTh.vue'),
    NumerouDepotMonquant: () => import('../component/NumerouDepotMonquant.vue'),
    selectStatue: () => import('./componentRapportTh/selectStatue.vue'),
    Lock: () => import('./componentRapportTh/Lock.vue'),
    inputFileExcelNumDossier: () =>
      import('./componentRapportTh/inputFileExcelNumDossier.vue')
  }
};
</script>

<style lang="scss" scoped>
.content-tab-users {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .box-content {
    .reload {
      color: #2dabe2;
      padding: 3px;
      margin-right: 5px;
      font-size: 10px;
      position: relative;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: rgb(160 160 160);
      }
    }
    .validationMessage {
      color: #e24747;
      font-size: 12px;
      margin-left: 7px;
    }
    .chargement {
      margin-left: 10px;
      font-size: 8px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .body-box-rapport {
      .tabs-body-style {
        .tab-item-style {
          display: inline;
          .title-tab-item {
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            font-weight: 500;
            color: #5d5d5d;
          }
          .ligne {
            width: 100%;
            margin: auto;
            margin-top: 7px;
            margin-bottom: 7px;
          }
          .body-tab-item {
            .badge-style {
              min-width: 51px;
              padding-top: 5px !important;
              padding-bottom: 5px !important;
              background-color: #6866b7 !important;
              color: #fff !important;
              border: 1px solid #6866b7;
              border-radius: 19px;
            }
          }
        }
      }
      .display-tabs {
        display: none;
      }
      .table-rapport-style {
        .table {
          height: calc(100% - 50px);
        }
        .b-table-sticky-header {
          max-height: calc(100vh - 175px) !important;
          height: calc(100vh - 175px);
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
      }
      .width-table-rapport {
        width: 100%;
      }
    }
  }
  .surface-box {
    .title {
      color: #5d5d5d;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      margin-left: 19%;
      margin-bottom: 2px;
    }
    .valueBox {
      text-align: center;
      margin: auto;
      border-radius: 10px;
      background-color: #f5f5ff;
      width: 200px;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: #ff7f8b;
    }
    .valueBoxStatue {
      text-align: center;
      margin: auto;
      border-radius: 10px;
      background-color: #f5f5ff;
      width: 200px;
      padding: 10px;
      font-size: 11px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: #5d5d5d;
      display: flex;
      div {
        width: 50%;
        .label-green {
          color: #13d613 !important;
        }
        .label-red {
          color: red !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.d-block {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.actionModel {
  text-align: center;

  .button-cancel-style {
    width: 100px;
  }
}
.swal2-actions {
  .swal2-confirm {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 9px;
    padding: 7px 15px;
    border: none;
    width: 68px;
    margin-right: 11px;
    outline: none;
  }
  .swal2-cancel {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 9px;
    padding: 8px 15px !important;
    border: none;
    height: 33px;
    outline: none;
  }
}
.thColorSearch {
  box-shadow: 0px 1px 1px #4d4bac33;
  background-color: #4d4bac12;
  padding: 2px;
  outline: none;
}
.desactive {
  color: #c5c5c5;
}
.active {
  color: #8d8cb7;
}
.classAlert,
.cardAnomalie {
  position: relative;
  background-color: #ededed !important;
  color: #858283;
  p {
    margin-bottom: 0px;
    font-size: 14px;
    width: 78px;
  }
}
.cardAnomalie {
  p {
    color: #fba5b3;
  }
}
.classAlert {
  p {
    color: #e8c559;
  }
}
.anomalie {
  width: 250px;
  text-align: left;
  padding-left: 28px;
  cursor: context-menu;
  b {
    &:hover {
      text-decoration: underline;
    }
  }
}
.custom-table-rapport-style {
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    font-size: 10px;
    padding: 0px 2px;
  }
  .col-num-dossier-th {
    text-align: start;
  }
}
.vue-select-modal-champ-custom {
  width: 137px;
  margin: auto;
  .select-vue-component-style-type {
    width: 100%;
  }
  .multiselect__placeholder {
    color: #fdfcfc;
    display: inline-block;
    margin-bottom: 0px;
    padding-left: 5px;
    padding-top: 0px;
    font-size: 10px;
    font-weight: 600;
  }
  .select-vue-component-style-type .multiselect__tags,
  .select-vue-component-style-type .multiselect__single {
    background: #8386d5;
  }
}
</style>
